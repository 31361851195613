<template>
    <v-layout fill-height>
        <v-layout column fill-height>
            <TreeStructureContent :value="value" />
        </v-layout>
        <v-navigation-drawer v-model="showCatalogFolderDetails" hide-overlay right :style="showCatalogFolderDetails ? 'width:40vw' : 'width:0'">
            <v-layout column fill-height>
                <v-flex shrink>
                    <v-layout row>
                        <v-toolbar flat>
                            <v-list>
                                <v-list-tile>
                                    <v-list-tile-title v-t="'sa.geds.folder_details'" class="title" />
                                    <v-list-tile-action>
                                        <v-btn icon @click="showCatalogFolderDetails = false">
                                            <v-icon>close</v-icon>
                                        </v-btn>
                                    </v-list-tile-action>
                                </v-list-tile>
                            </v-list>
                        </v-toolbar>
                    </v-layout>
                </v-flex>
                <v-flex scroll-y>
                    <FolderDetails v-model="catalogFolder" :catalog-tree-structure-id="value" />
                </v-flex>
            </v-layout>
        </v-navigation-drawer>
    </v-layout>
</template>
<script>
import SuperAdminCatalogTreeStructureModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminCatalogTreeStructureModuleGuard'


export default {
    name: 'DocumentsStructuresDetails',
	components: {
		FolderDetails: () => ({
			component: import('@/components/SuperAdmin/Documents/FolderDetails')
		}),
		TreeStructureContent: () => ({
			component: import('@/components/SuperAdmin/Documents/TreeStructureContent')
		})
	},
    mixins: [SuperAdminCatalogTreeStructureModuleGuard],
    props: {
        value: {
            required: true,
            type: Number
        }
    },
    data: function () {
		return {
            showCatalogFolderDetails: false,
			catalogFolder: null,
        }
    },
    methods :{
        getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.SELECT_CATALOG_FOLDER, action: this.getCatalogFolder },
			]
		},
        getCatalogFolder: function (catalogTreeFolderId) {
			if (catalogTreeFolderId) {
				this.service
					.getCatalogFolder(this.value, catalogTreeFolderId, {
						fields: ['*', 'catalog_flags', 'catalog_themes', 'catalog_tree_tools']
					})
					.then(catalogFolder => {
						this.catalogFolder = catalogFolder
						this.showCatalogFolderDetails = true
					})
					.catch((err) => {
						this.catalogTreeFolder = null
						this.showCatalogFolderDetails = false
						throw err
					})
			} else {
				this.showCatalogFolderDetails = false
			}
		},
    }
}
</script>
